import React from "react"
import { Headline, StyledHeadlineWrapper } from "./headline"
import styled from "styled-components"
import "../styles/contact.scss"
import { DefaultOptionsQuery } from "../hooks/defaultOptions"
import { useForm } from "@formcarry/react"

const StyledWrapper = styled.div`
    padding-bottom: 100px;
    background-color: #304040;
    color: white;
    
    input, textarea {
      color: white;
      background: transparent;
      border-width: 0 0 1px 0;
      border-style: solid;
      border-color: white;
    }
`

const StyledTextarea = styled.textarea`
    resize: none;
`

export const Contact = () => {
  const data = DefaultOptionsQuery()
  const { state, submit } = useForm({
    id: "0yJ9mhfFWUai",
  })

  // Success message
  if (state.submitted) {
    return (
      <StyledWrapper>
        <div className={"container"}>
          <div className={"row justify-content-center"}>
            <div className={"col-10"}>
              <StyledHeadlineWrapper>
                <Headline headlineH2="Danke für deine Nachricht"
                          thinSubline={data.wp.startseite.acfThemeSettings.contact_danke}/>
              </StyledHeadlineWrapper>
            </div>
          </div>
        </div>
      </StyledWrapper>
    )
  }

  return (
    <StyledWrapper>
      <div className={"container"}>
        <div className={"row justify-content-center"}>
          <div className={"col-10"}>
            <StyledHeadlineWrapper>
              <Headline headlineH2={data.wp.startseite.acfThemeSettings.contact_headline}
                        thinSubline={data.wp.startseite.acfThemeSettings.contact_subline}/>
            </StyledHeadlineWrapper>

            <form onSubmit={submit}>
              <input type="hidden" name="_gotcha"/>

              <div className="form-row">
                <div className="col-12 col-md-6">
                  <div className="form-label-group">
                    <input type="text" id="inputName" name="Name" className="form-control" placeholder="Dein Name"
                           required
                    />
                    <label htmlFor="inputName">Dein Name</label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-label-group">
                    <input type="email" id="inputEmail" name="Email" className="form-control"
                           placeholder="Deine Email-Adresse"
                           required/>
                    <label htmlFor="inputEmail">Deine Email-Adresse</label>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-12">
                  <div className="form-label-group">
                    <StyledTextarea id="inputMessage"
                                    className="form-control"
                                    placeholder="Deine Nachricht an uns"
                                    rows="1"
                                    name="Nachricht"
                                    maxlength="1000"
                                    required
                    />
                    <label htmlFor="inputMessage">Deine Nachricht an uns</label>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col-12 text-center">
                  <button className="btn btn-outline-medium" type="submit">Kontakt aufnehmen</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </StyledWrapper>
  )
}
